import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';

const VideoGrid = ({ videoIds }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/videos', {
          params: {
            key: 'AIzaSyDoT4w7Xe97qQej2894D0Z7FHK2jCqGjH8',
            part: 'snippet,status',
            id: videoIds.join(',')
          },
        });

        if (response.data.items.length === 0) {
          setError('Videos not found');
          setLoading(false);
          return;
        }

        const fetchedVideos = response.data.items.map(video => ({
          id: video.id,
          title: video.snippet.title,
          description: video.snippet.description,
          thumbnail: video.snippet.thumbnails.high.url,
          embeddable: video.status.embeddable,
        }));

        setVideos(fetchedVideos);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideoDetails();
  }, [videoIds]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <GridContainer>
      {videos.map((video) => (
        <VideoWrapper key={video.id}>
          {video.embeddable ? (
            <iframe
              title={video.title}
              width="100%"
              height="200"
              style={{ borderRadius: '8px', border: '1px solid #ddd' }}
              src={`https://www.youtube.com/embed/${video.id}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <CenteredContent>
              <ThumbnailLink href={`https://www.youtube.com/watch?v=${video.id}`} target="_blank" rel="noopener noreferrer">
                <Thumbnail src={video.thumbnail} alt={video.title} />
                <PlayButton />
              </ThumbnailLink>
            </CenteredContent>
          )}
        </VideoWrapper>
      ))}
    </GridContainer>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  padding: 20px;

  @media(min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media(min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const VideoWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ddd;
`;

const CenteredContent = styled.div`
  text-align: center;
`;

const ThumbnailLink = styled.a`
  display: inline-block;
  position: relative;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const PlayButton = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.8) url('https://developers.google.com/static/site-assets/logo-youtube.svg') no-repeat center/50%;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    animation: ${pulse} 1s infinite;
  }
`;

export default VideoGrid;
