import React from 'react'
import Portfolio from '../components/portfolio'
import EditingStyle from '../components/editingStyle'
import About from '../components/about'
import styled from 'styled-components'
import ContactForm from '../components/contactForm'
import MainEmbed from '../components/mainEmbed'
import Review from '../components/reviews'

const Container = styled.div`
  display: flex;
  @media (max-width: 700px) {
    display: block;
  }
`

const LeftDiv = styled.div`
  width: 70%;
  @media (max-width: 700px) {
    width: 100%;
  }
`
const RightDiv = styled.div`
  width: 100%;
  @media (min-width: 700px) {
    width: 30%;
    height: 100vh;
    background: transparent;    
    position: fixed;
    right: 0;
    top: 0;
  }
`

const Home = () => {
  return (
    <Container>
      <LeftDiv>
        <MainEmbed videoId={'pQNgtwOgii0?si=1tzj1eBtuHykM485'} />
        <div style={{ padding: '20px' }}>
          <About />
          <EditingStyle />
          <Portfolio />
          <Review />
        </div>
      </LeftDiv>
      <RightDiv>
          <ContactForm />
      </RightDiv>
    </Container>
  )
}

export default Home