// src/AnalyticsPage.js
import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AnalyticsView from './analytics';

const clientId = '578770104710-re9eo21rnejh70farvvc32kmmgn7geo3.apps.googleusercontent.com';

const AnalyticsPage = () => (
  <GoogleOAuthProvider clientId={clientId}>
    <AnalyticsView />
  </GoogleOAuthProvider>
);

export default AnalyticsPage;
