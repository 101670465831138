import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const VideoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
`;

const VideoWrapper = styled.div`
  flex: 1 1 100%;
  height: auto;
  border: 1px #4991fd solid;
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  justify-items: center;
  text-align: center;
  padding: 20px 0;
  @media (min-width: 600px) {
    flex: 1 1 calc(50% - 10px); /* 2 columns for tablets */
  }
  @media (min-width: 900px) {
    flex: 1 1 calc(33.333% - 10px); /* 3 columns for desktops */
  }
`;

const Thumbnail = styled.img`
  max-width: 100%;
  height: auto;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const PlayButton = styled.span`
  position: absolute;
  top: 25%;
  left: 35%;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.8) url('https://developers.google.com/static/site-assets/logo-youtube.svg') no-repeat center/50%;
  border-radius: 50%;
  cursor: pointer;
  transform-origin: center; /* Ensure the button scales from its center */
  &:hover {
    animation: ${pulse} 1s infinite;
  }
`;

const ThumbnailLink = styled.a`
  display: inline-block;
  position: relative;
`;

const PaginationButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 5px;
  padding: 5px;
  color: #4991fd;
  font-size: 1.5em;
  &:disabled {
    color: grey;
    cursor: not-allowed;
  }
`;

const PageButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #4991fd;
  color: white;
  cursor: pointer;
  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
`;

const YouTubePlaylist = ({ playlistId, apiKey }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const videosPerPage = 10;

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/playlistItems`,
          {
            params: {
              part: 'snippet,contentDetails',
              playlistId: playlistId,
              maxResults: 50, // Max results per page
              key: apiKey,
            },
          }
        );

        const videoDetails = await Promise.all(
          response.data.items.map(async (item) => {
            const videoId = item.contentDetails.videoId;
            const videoResponse = await axios.get(
              `https://www.googleapis.com/youtube/v3/videos`,
              {
                params: {
                  part: 'status,contentDetails',
                  id: videoId,
                  key: apiKey,
                },
              }
            );

            const videoData = videoResponse.data.items[0];
            const isAgeRestricted = videoData.contentDetails.contentRating?.ytRating === 'ytAgeRestricted';
            const embeddable = videoData.status.embeddable && !isAgeRestricted;

            return {
              ...item.snippet,
              videoId,
              embeddable,
              isAgeRestricted,
            };
          })
        );

        setVideos(videoDetails);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [playlistId, apiKey]);

  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <VideoContainer>
        {currentVideos.map((video) => (
          <VideoWrapper key={video.videoId}>
            <h3>{video.title}</h3>
            {video.embeddable ? (
              <iframe
                width="300"
                height="200"
                src={`https://www.youtube.com/embed/${video.videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={video.title}
              ></iframe>
            ) : (
              <ThumbnailLink href={`https://www.youtube.com/watch?v=${video.videoId}`} target="_blank" rel="noreferrer">
                <Thumbnail src={video.thumbnails.medium.url} />
                <PlayButton />
              </ThumbnailLink>
            )}
          </VideoWrapper>
        ))}
      </VideoContainer>
      <PaginationButtons>
        <ArrowButton onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
          <FaArrowLeft />
        </ArrowButton>
        {Array.from({ length: Math.ceil(videos.length / videosPerPage) }, (_, index) => (
          <PageButton key={index + 1} onClick={() => paginate(index + 1)} disabled={index + 1 === currentPage}>
            {index + 1}
          </PageButton>
        ))}
        <ArrowButton onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(videos.length / videosPerPage)}>
          <FaArrowRight />
        </ArrowButton>
      </PaginationButtons>
    </>
  );
};

export default YouTubePlaylist;
