import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router';
import { IoArrowBackSharp } from "react-icons/io5";
import YouTubePlaylist from '../components/youtubePlaylist';

const clockwise = keyframes`
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;    
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;    
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;    
  }
`;

const counterclockwise = keyframes`
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;    
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;    
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;    
  }
`;

const StyledDiv = styled.div`
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  color: #fff;
  background: #6849fd;
  border-radius: 12px;
  transition: 1s box-shadow;
  padding: 36px 0px;
  cursor: pointer;
  border: 1px solid rgba(120,120,120,.2);
  margin-top: 20px;

  &:hover {
    box-shadow: 0 5px 35px 0px rgba(0, 0, 0, 0.1);
  }

  &:hover::before, &:hover::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FDA8CF;
    border-radius: 12px;
    z-index: -1;
    animation: ${clockwise} 1s infinite;
  }

  &:hover::after {
    background: #F3CE5E;
    animation: ${counterclockwise} 2s infinite;
  }
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  @media (max-width: 750px) {
    display: block;
  }
  div{
    font-size: 12px; 
    font-weight:400; 
    @media (max-width: 750px) {
    display: block;
  }
  }
`;

const ExtraContent = styled.div`
  display: none;
  padding: 0px 10px;
`;


const Ugc = () => {
  const navigate = useNavigate();

  return (
    <div style={{padding:'20px'}}>
      <StyledDiv>
        <Header><div onClick={()=> navigate('/')} style={{display:'flex', alignItems:'center', gap:'5px'}}><IoArrowBackSharp /> Go Back</div><div>UGC Facebook Ads</div></Header>
        <ExtraContent>
          <p>Find your style</p>
        </ExtraContent>
      </StyledDiv>
      <YouTubePlaylist playlistId={"PLdklcLR-RKh8EjUkMXVWXH6y3y3NwwHvu"} apiKey={"AIzaSyDoT4w7Xe97qQej2894D0Z7FHK2jCqGjH8"} />
    </div>
  );
};

export default Ugc;
