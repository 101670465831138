import React from 'react';
import styled, { keyframes } from 'styled-components';
import YouTubeEmbed from './youtubeEmbed';
import Ewu from '../assets/ewu.jpg';
import Ronan from '../assets/ronan.jpg';
import Aubrey from '../assets/aubrey.jpg';
import Turtle from '../assets/turtle.jpg';
import Discovery from '../assets/discovery.jpg'
import Ipm from '../assets/ipm.jpg'
import John from '../assets/john.jpg'
import Parentpro from '../assets/parentpro.jpg'
import { useNavigate } from 'react-router';
import { IoArrowBackSharp } from "react-icons/io5";



const clockwise = keyframes`
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;    
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;    
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;    
  }
`;

const counterclockwise = keyframes`
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;    
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;    
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;    
  }
`;

const StyledDiv = styled.div`
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  background: #4991fd;
  color: white;
  border-radius: 12px;
  transition: 1s box-shadow;
  padding: 36px 0px;
  cursor: pointer;
  border: 1px solid rgba(120,120,120,.2);
  margin-top: 20px;

  &:hover {
    box-shadow: 0 5px 35px 0px rgba(0, 0, 0, 0.1);
  }

  &:hover::before, &:hover::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FDA8CF;
    border-radius: 12px;
    z-index: -1;
    animation: ${clockwise} 1s infinite;
  }

  &:hover::after {
    background: #F3CE5E;
    animation: ${counterclockwise} 2s infinite;
  }
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  @media (max-width: 750px) {
    display: block;
  }
`;

const ExtraContent = styled.div`
  display: block;
  padding: 0px 10px;
`;

const HiddenContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const HiddenDiv = styled.div`
  flex: 1 1 100%;
  height: auto;
  border: 1px #cecccc solid;
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  justify-items: center;
  text-align: center;
  padding: 20px 0;

  @media (min-width: 600px) {
    flex: 1 1 calc(50% - 10px);
  }
`;

const YoutubeClients = () => {
  const navigate = useNavigate();


  return (
    <div style={{margin:'20px'}}>
      <StyledDiv>
      <Header><div onClick={()=> navigate('/')} style={{display:'flex', alignItems:'center', gap:'5px'}}><IoArrowBackSharp /> Go Back</div><div>Youtube Clients</div></Header>
      <ExtraContent>
          <p>Projects from creators I have worked with</p>
        </ExtraContent>
      </StyledDiv>
      <HiddenContainer>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <img src={Ewu} alt="Channel" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>Explore With us</h3>
            <i style={{fontSize:'12px', textDecoration:''}}><b>EXPLORE WITH US </b>is a channel with over 5 Million Subscribers dedicated to all things mystery, including unsolved cold cases, true crime, and more</i>
            <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Edmond is a good and responsive editor”<b>- Kristopher Tulud (Creative Director “Explore With Us EWU”)</b></span></p>
            {/* <p>I specialised in Video editing of both short form and long form True Crime content</p> */}
          </div>
            <YouTubeEmbed videoId={"xrCmFutB1Uc"} />
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <img src={Ronan} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>Glidget Ronan</h3>
            <i style={{fontSize:'12px', textDecoration:''}}><b>(60k Subs)</b>Youtube channel on strategic communication breakdown and analysis</i>
            <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Amazing editor in every way! Edmond has been great.” - <b>Glidget Ronan</b></span></p>
          </div>
          <YouTubeEmbed videoId="Un8-KTOBLik" />
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <img src={Aubrey} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>Aubrey Janik</h3>
            <i style={{fontSize:'12px', textDecoration:''}}><b>(139k Subs)</b>Financial and Car Market Talking Head Videos</i>
            {/* <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Edmond is a good and responsive editor” <b>- Kristopher Tulud (Creative Director “Explore With Us EWU”)</b></span></p> */}
          </div>  
          <YouTubeEmbed videoId="8aP5zjIoEfs" />
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <img src={Turtle} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>Turtleneck Philosophy</h3>
            <i style={{fontSize:'12px', textDecoration:''}}><b>(19k Subs)</b>Turtleneck Philosophy is a philosophical youtube channel with over 19,000 subscribers</i>
            {/* <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Edmond is a good and responsive editor” - <b>Kristopher Tulud (Creative Director “Explore With Us EWU”)</b></span></p> */}
          </div>  
          <YouTubeEmbed videoId="GJJ68EWPeHs"/>
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <img src={Parentpro} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>Parent Pro Tech</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>Equipping Families and educators and students with videos on how to navigate tech</i>
            <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Edmond, I love the videos man! You did a GREAT job of adding graphics and audio to make this long introduction much more engaging and fun to watch. Great work!! 😃” - <b>Joshua (Creative Director at Parent Protech)</b></span></p>
          </div>
          <YouTubeEmbed videoId="hY2UuUb5A3g"/>
        </HiddenDiv>
        <HiddenDiv>
        <div style={{padding:'20px'}}>
            <img src={Discovery} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>Discovery Mind</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>Youtube Documentary channel on History, Geography and Facts</i>
            {/* <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Edmond is a good and responsive editor” - <b>Kristopher Tulud (Creative Director “Explore With Us EWU”)</b></span></p> */}
          </div>  
          <YouTubeEmbed videoId="Gk9-hnJ8muc"/>
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <img src={John} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>John in a C8</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>Youtube Channel on Pranks</i>
            {/* <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Edmond is a good and responsive editor” - <b>Kristopher Tulud (Creative Director “Explore With Us EWU”)</b></span></p> */}
          </div>
          <YouTubeEmbed videoId="t7D7RP7ZZqk"/>
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <img src={Ipm} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>International Product Manager</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>Youtube talking head educational channel on Product Management</i>
            {/* <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Edmond is a good and responsive editor” - <b>Kristopher Tulud (Creative Director “Explore With Us EWU”)</b></span></p> */}
          </div>  
          <YouTubeEmbed videoId="Z6bMAn-shh8"/>
        </HiddenDiv>
        {/* <HiddenDiv>
          <div style={{padding:'20px'}}>
            <img src={Youtube} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>Other Youtube Videos</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>These are some other youtube videos I have edited</i>
            <p>Talking Head Videos, Documentaries, Podcasts, etc</p>
          </div>
          <YouTubeEmbed videoId="sJegziYaanE"/>
        </HiddenDiv> */}
      </HiddenContainer>
    </div>
  );
};

export default YoutubeClients;
