import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router';
import { IoArrowBackSharp } from "react-icons/io5";

const clockwise = keyframes`
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;    
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;    
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;    
  }
`;

const counterclockwise = keyframes`
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;    
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;    
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;    
  }
`;

const StyledDiv = styled.div`
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  background: #bc49fdab;
  color: white;
  border-radius: 12px;
  transition: 1s box-shadow;
  padding: 36px 0px;
  cursor: pointer;
  border: 1px solid rgba(120,120,120,.2);
  margin-top: 20px;

  &:hover {
    box-shadow: 0 5px 35px 0px rgba(0, 0, 0, 0.1);
  }

  &:hover::before, &:hover::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FDA8CF;
    border-radius: 12px;
    z-index: -1;
    animation: ${clockwise} 1s infinite;
  }

  &:hover::after {
    background: #F3CE5E;
    animation: ${counterclockwise} 2s infinite;
  }
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  @media (max-width: 750px) {
    display: block;
  }
`;


const HiddenContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const HiddenDiv = styled.div`
  flex: 1 1 100%;
  height: auto;
  border: 1px #cecccc solid;
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  justify-items: center;
  text-align: center;
  padding: 20px 0;

  @media (min-width: 600px) {
    flex: 1 1 calc(50% - 10px);
  }
`;

const ReviewPage = () => {
const navigate = useNavigate();
  return (
    <div style={{margin:'20px'}}>
      <StyledDiv>
        <Header><div onClick={()=> navigate('/')} style={{display:'flex', alignItems:'center', gap:'5px'}}><IoArrowBackSharp /> Go Back</div><div>Reviews <div style={{fontSize:'12px', fontWeight:'200'}}>⭐⭐⭐⭐⭐</div></div></Header>
      </StyledDiv>
      <HiddenContainer>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Edmond is a good and responsive editor”<b>- Kristopher Tulud (Creative Director “Explore With Us EWU”)</b></span></p>
          </div>
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Amazing editor in every way! Edmond has been great.” - <b>Glidget Ronan</b></span></p>
          </div>
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
          <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Awesome editing and prompt turn around time” - <b>James Buchanan (Social Media Ads Agency Owner)</b></span></p>
          </div>  
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Edmond is awesome! Nailed it first time around!” - <b>Amy Vale (Owner forthecubs.com)</b></span></p>
          </div>  
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Edmond, I love the videos man! You did a GREAT job of adding graphics and audio to make this long introduction much more engaging and fun to watch. Great work!! 😃” - <b>Joshua (Creative Director at Parent Protech)</b></span></p>
          </div>
        </HiddenDiv>
        <HiddenDiv>
        <div style={{padding:'20px'}}>
            <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Our company engaged Edmond to produce a new product Explainer Video, requiring both horizontal and vertical formats. With minimal design guidance, Edmond delivered an exceptional product, utilizing the script we provided. Communication with him was effortless, and he promptly addressed any revisions required” - <b>Blender Media</b></span></p>
          </div>  
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Excellent work! Great communication. Easy to work with!” - <b>Damian Zhang (CEO Carmatsculture)</b></span></p>
          </div>
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Edmond is a great listener, a great editor, and has wonderfully creative ideas. He's professional and highly valued.” - <b>Nathan Crane (Director HealingLife LLC)</b></span></p>
          </div>  
        </HiddenDiv>
        <HiddenDiv>
          <div style={{padding:'20px'}}>
            <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Thank you Edmond for the fast turnaround and being so easy to work with” - <b>Brennan Sexton (CEO Simply Big)</b></span></p>
          </div>
        </HiddenDiv>
      </HiddenContainer>
    </div>
  );
};

export default ReviewPage;
