import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Edmond from '../assets/square.jpg';

const clockwise = keyframes`
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;    
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;    
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;    
  }
`;

const counterclockwise = keyframes`
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;    
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;    
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;    
  }
`;

const StyledDiv = styled.div`
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  background: #3edd84;
  border-radius: 12px;
  transition: 1s box-shadow;
  padding: 36px 0px;
  color: #fff; 
  cursor: pointer;
  border: 1px solid rgba(120,120,120,.2);

  &:hover {
    box-shadow: 0 5px 35px 0px rgba(0, 0, 0, 0.1);
  }

  &:hover::before, &:hover::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FDA8CF;
    border-radius: 12px;
    z-index: -1;
    animation: ${clockwise} 1s infinite;
  }

  &:hover::after {
    background: #F3CE5E;
    animation: ${counterclockwise} 2s infinite;
  }
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  @media (max-width: 750px) {
    display: block;
  }
`;

const ExtraContent = styled.div`
  display: ${props => (props.expanded ? 'block' : 'none')};
  padding: 0px 10px;
`;

const HiddenContainer = styled.div`
  display: ${props => (props.expanded ? 'flex' : 'none')};
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const HiddenDiv = styled.div`
  flex: 1 1 100%;
  height: auto;
  border: 1px #6849fd solid;
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  justify-items: center;
  text-align: center;
  padding: 20px 0;

  @media (min-width: 600px) {
    flex: 1 1 calc(50% - 10px);
  }
`;

const About = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <StyledDiv expanded={expanded} onClick={handleToggle}>
        <Header>About Me <div style={{fontSize:'12px', fontWeight:'200'}}>Edmond</div></Header>
        <ExtraContent expanded={expanded}>
          <p>I edit videos for a living</p>
        </ExtraContent>
      </StyledDiv>
      <HiddenContainer expanded={expanded}>
        <HiddenDiv>
            <img src={Edmond} alt="Channel" style={{width:'80%', height:'90%', borderRadius:'12px'}}/>
        </HiddenDiv>
        <HiddenDiv style={{textAlign:'left', padding:'20px'}}>  
            <p>Welcome to my world of video editing! I am a professional video editor with over 5 years experience. My main playground is YouTube, where I've worked on all sorts of videos—like talking head videos, documentaries, funny pranks, gaming clips, and even podcast episodes. I have rich experience editing direct response and e-commerce videos, creating and editing reels on Facebook, Instagram, and TikTok, as well as video sales letter,

                I've edited several YouTube videos gathering over 50 million views! Editing Video ads for businesses that has driven over 1 million dollars in sales, demonstrating a keen understanding of brand storytelling and consumer engagement. </p>

                <p>At the heart of my work is a passion for transforming ideas into visual stories that not only entertain but also inform and inspire. Whether you're a brand looking to skyrocket your online presence, a content creator aiming to captivate your audience, or a business seeking to boost your sales through compelling videos, I bring the dedication, creativity, and expertise necessary to make your vision come alive.

                I'm looking for new projects and challenges, whether it's working full time or part time</p>

                <p>Let's embark on this exciting journey together and create video content that stands out, engages, and converts. Your vision, coupled with my video editing prowess, is the perfect recipe for success. Let's make something amazing together.
            </p>
        </HiddenDiv>
      </HiddenContainer>
    </>
  );
};

export default About;
