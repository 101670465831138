import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Input = styled.input`
    border: 1px solid #8C8C8C;
    border-radius: 12px;
    padding: 16px 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: left;
    color: #404040;
    &:focus {
        outline: none; 
    }
`;

const InputArea = styled.textarea`
    border: 1px solid #8C8C8C;
    border-radius: 12px;
    padding: 16px 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: left;
    color: #404040;
    &:focus {
        outline: none; 
    }
`;

const PrimaryButton = styled.button`
  width: 100%;
  height: 49px;
  padding: 16px 104px 16px 104px;
  border-radius: 24px;
  background: #00A667; 
  color: #fff; 
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;
  display: inline;

  &:hover {
    background: #04603D; 
    color: #fff;
  }
`;

const LoginForm = styled.form`
    justify-content: center;
    align-items: center;
`;

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading("Sending message...");
    try {
      const response = await axios.post('https://api.edmondedit.com/mail.php', {
        name,
        email,
        message,
      });
      console.log(response);
      toast.update(toastId, { render: "Email sent successfully", type: "success", isLoading: false, autoClose: 5000 });
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      toast.update(toastId, { render: "Failed to send email", type: "error", isLoading: false, autoClose: 5000 });
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h3>Contact</h3>
      <LoginForm onSubmit={handleSubmit}>
        <div style={{ display: 'grid', gap: '5px', marginBottom: '10px' }}>
          <label>Name</label>
          <Input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div style={{ display: 'grid', gap: '5px', marginBottom: '10px' }}>
          <label>Email</label>
          <Input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div style={{ display: 'grid', gap: '5px', marginBottom: '10px' }}>
          <label>Message</label>
          <InputArea
            placeholder="Your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div>
          <PrimaryButton type="submit">Send Mail</PrimaryButton>
        </div>
      </LoginForm>
      <ToastContainer />
    </div>
  );
};

export default ContactForm;
