import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';

const YouTubeEmbed = ({ videoId }) => {
  const [embedAllowed, setEmbedAllowed] = useState(true);
  const [videoInfo, setVideoInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/videos', {
          params: {
            key: 'AIzaSyDoT4w7Xe97qQej2894D0Z7FHK2jCqGjH8',
            part: 'snippet,status',
            id: videoId,
          },
        });

        if (response.data.items.length === 0) {
          setError('Video not found');
          setLoading(false);
          return;
        }

        const video = response.data.items[0];
        const { snippet, status } = video;

        if (!status.embeddable) {
          setEmbedAllowed(false);
        }

        setVideoInfo({
          title: snippet.title,
          description: snippet.description,
          thumbnail: snippet.thumbnails.high.url,
        });
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideoDetails();
  }, [videoId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <Container>
      {embedAllowed ? (
        <iframe
          title="YouTube video player"
          width="100%"
          height="315"
          style={{ borderRadius: '12px' }}
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <CenteredContent>
          <ThumbnailLink href={`https://www.youtube.com/watch?v=${videoId}`} target="_blank" rel="noopener noreferrer">
            <Thumbnail src={videoInfo?.thumbnail} alt="Video Thumbnail" />
            <PlayButton />
          </ThumbnailLink>
        </CenteredContent>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const CenteredContent = styled.div`
  text-align: center;
`;

const ThumbnailLink = styled.a`
  display: inline-block;
  position: relative;
`;

const Thumbnail = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 12px;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const PlayButton = styled.span`
  position: absolute;
  top: 25%;
  left: 35%;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.8) url('https://developers.google.com/static/site-assets/logo-youtube.svg') no-repeat center/50%;
  border-radius: 50%;
  cursor: pointer;
  transform-origin: center; /* Ensure the button scales from its center */
  &:hover {
    animation: ${pulse} 1s infinite;
  }
`;

export default YouTubeEmbed;
