import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router';
import Dtc from '../assets/ecom.gif'
import Youtubepic from '../assets/youtube-transparent.png'


const clockwise = keyframes`
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;    
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;    
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;    
  }
`;

const counterclockwise = keyframes`
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;    
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;    
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;    
  }
`;

const StyledDiv = styled.div`
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  background: #4991fd;
  color: white;
  border-radius: 12px;
  transition: 1s box-shadow;
  padding: 36px 0px;
  cursor: pointer;
  border: 1px solid rgba(120,120,120,.2);
  margin-top: 20px;

  &:hover {
    box-shadow: 0 5px 35px 0px rgba(0, 0, 0, 0.1);
  }

  &:hover::before, &:hover::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FDA8CF;
    border-radius: 12px;
    z-index: -1;
    animation: ${clockwise} 1s infinite;
  }

  &:hover::after {
    background: #F3CE5E;
    animation: ${counterclockwise} 2s infinite;
  }
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  @media (max-width: 750px) {
    display: block;
  }
`;

const ExtraContent = styled.div`
  display: ${props => (props.expanded ? 'block' : 'none')};
  padding: 0px 10px;
`;

const HiddenContainer = styled.div`
  display: ${props => (props.expanded ? 'flex' : 'none')};
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const HiddenDiv = styled.div`
  flex: 1 1 100%;
  height: auto;
  border: 1px #cecccc solid;
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  justify-items: center;
  text-align: center;
  padding: 20px 0;
  cursor: pointer;

  @media (min-width: 600px) {
    flex: 1 1 calc(50% - 10px);
  }
`;

const Portfolio = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const navigate = useNavigate();

  return (
    <>
      <StyledDiv expanded={expanded} onClick={handleToggle}>
        <Header>Portfolio <div style={{fontSize:'12px', fontWeight:'200'}}>People I've worked with</div></Header>
        <ExtraContent expanded={expanded}>
          <p>Projects from creators and brands I have worked with</p>
        </ExtraContent>
      </StyledDiv>
      <HiddenContainer expanded={expanded}>
        <HiddenDiv onClick={()=> navigate('/youtube-client')}>
          <div style={{padding:'20px'}}>
            <h3 style={{marginBottom:'10px'}}>Youtube Clients</h3>
            <img src={Youtubepic} alt="Channel" style={{width:'auto', height:'100px', borderRadius:'8px'}}/>
            <div><i style={{fontSize:'14px', textDecoration:'', fontWeight:'500'}}>Some Youtube Channels I have worked with.</i></div>
            {/* <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Edmond is a good and responsive editor”<b>- Kristopher Tulud (Creative Director “Explore With Us EWU”)</b></span></p> */}
            {/* <p>I specialised in Video editing of both short form and long form True Crime content</p> */}
          </div>
        </HiddenDiv>
        <HiddenDiv onClick={()=> navigate('/dtc')}>
          <div style={{padding:'20px'}}>
            <h3 style={{marginBottom:'10px'}}>DTC/UGC/Ecommerce Brands</h3>
            <img src={Dtc} alt="Avatar" style={{width:'200px', height:'200px', borderRadius:'8px'}}/>
            <div><i style={{fontSize:'14px', textDecoration:'', fontWeight:'500'}}>Some videos of Direct to Consumer, User generated content and Ecommerce brands I've worked with</i></div>
            {/* <p style={{display:'grid'}}><span>⭐⭐⭐⭐⭐</span> <span>"Amazing editor in every way! Edmond has been great.” - <b>Glidget Ronan</b></span></p> */}
          </div>
        </HiddenDiv>
      </HiddenContainer>
    </>
  );
};

export default Portfolio;
