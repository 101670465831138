import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from './pages';
import Documentary from './pages/documentary';
import Ugc from './pages/ugc';
import Shorts from './pages/shorts';
import TalkingHead from './pages/talking-head';
import SimplyBig from './pages/simplybig';
import BounceNutrition from './pages/bounce-nutrition';
import SleepBySanti from './pages/sleep';
import Raycon from './pages/raycon';
import ClearlyFiltered from './pages/clearly';
import CarMats from './pages/car-mats';
import TinyBio from './pages/tinybio';
import Other from './pages/other';
import YoutubeClients from './components/youtubeclients';
import Dtc from './components/dtc';
import ReviewPage from './pages/reviewPage';
import usePageTracking from './hook/usePageTracking';
import AnalyticsPage from './pages/analyticspage';


function App() {
  usePageTracking();
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/documentary' element={<Documentary />} />
        <Route path='/ugc' element={<Ugc />} />
        <Route path='/shorts' element={<Shorts />} />
        <Route path='/talking-head' element={<TalkingHead />} />
        <Route path='/simply-big' element={<SimplyBig />} />
        <Route path='/bounce-nutrition' element={<BounceNutrition />} />
        <Route path='/sleep-by-santi' element={<SleepBySanti />} />
        <Route path='/raycon' element={<Raycon />} />
        <Route path='/clearly-filtered' element={<ClearlyFiltered />} />
        <Route path='/car-mats-culture' element={<CarMats />} />
        <Route path='/tiny-bio' element={<TinyBio />} />
        <Route path='/other-ecom-brands' element={<Other /> } />
        <Route path='/youtube-client' element={<YoutubeClients /> } />
        <Route path='/dtc' element={<Dtc /> } />
        <Route path='/reviews' element={<ReviewPage /> } />
        <Route path='/analytics' element={<AnalyticsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
