import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.div`
  width: 200px; /* Adjust the width as needed */
  height: 200px; /* Adjust the height as needed */
  overflow: hidden;
  border-radius: 8px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the image covers the container completely */
  border-radius: 8px;
`;

const ImageComponent = ({ src, alt }) => {
  return (
    <Container>
      <ImageWrapper>
        <StyledImage src={src} alt={alt} />
      </ImageWrapper>
    </Container>
  );
};

export default ImageComponent;
