import React, { useState, useEffect } from 'react';
import axios from 'axios';
import YouTube from 'react-youtube'; // Import React YouTube package
import styled, { keyframes } from 'styled-components';

const MainEmbed = ({ videoId, thumbNail }) => {
  const [embedAllowed, setEmbedAllowed] = useState(true);
  const [videoInfo, setVideoInfo] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/videos', {
          params: {
            key: 'YOUR_API_KEY_HERE',
            part: 'snippet,status',
            id: videoId,
            videoEmbeddable: true,
          },
        });

        if (response.data.items.length === 0) {
          console.log('Video not found');
          return;
        }

        const video = response.data.items[0];
        const { snippet, status } = video;

        // Check if embedding is allowed
        if (!status.embeddable) {
          setEmbedAllowed(false);
        }

        setVideoInfo({
          title: snippet.title,
          description: snippet.description,
          thumbnail: snippet.thumbnails.high.url, // or choose appropriate size
        });
      } catch (error) {
        console.error('Error fetching video details:', error);
      }
    };

    fetchVideoDetails();
  }, [videoId]);

  const onReady = (event) => {
    // Access the YouTube player instance here
    // You can optionally start playing the video here
    event.target.playVideo();
  };

  return (
    <Container>
      {embedAllowed ? (
        <YouTube
          videoId={videoId}
          opts={{
            width: '100%',
            height: isMobile ? 'auto' : '315px',
            playerVars: {
              autoplay: 1,
              mute: 1, // Mute the video for autoplay to work on most browsers
            },
          }}
          onReady={onReady}
          containerClassName="youtube-container"
        />
      ) : (
        <CenteredContent>
          <ThumbnailLink
            href={`https://www.youtube.com/watch?v=${videoId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Thumbnail src={videoInfo?.thumbnail || thumbNail} alt="Video Thumbnail" />
            <PlayButton />
          </ThumbnailLink>
        </CenteredContent>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .div{
    width: 100% !important;
  }
  div{
    width: 100% !important;
    border-radius: 20px !important;
  }
`;

const CenteredContent = styled.div`
  text-align: center;
`;

const ThumbnailLink = styled.a`
  display: inline-block;
  position: relative;
`;

const Thumbnail = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 12px;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const PlayButton = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.8) url('https://developers.google.com/static/site-assets/logo-youtube.svg') no-repeat center/50%;
  border-radius: 50%;
  cursor: pointer;
  transform-origin: center; /* Ensure the button scales from its center */
  &:hover {
    animation: ${pulse} 1s infinite;
  }
`;

export default MainEmbed;
