import React from 'react';
import styled, { keyframes } from 'styled-components';
import SimplyBig from '../assets/simplyBig.webp'
import ImageComponent from './Image';
import BounceNutrition from '../assets/bouncenutrition.png'
import SleepBySanti from '../assets/sleepbysanti.jpg'
import Raycon from '../assets/raycon-everyday-earbuds-2023-2.avif'
import ClearlyFiltered from '../assets/clearlyfiltered.webp'
import Carmats from '../assets/carmatsculture.jpeg'
import TinyBio from '../assets/tinybio.jpeg'
import { useNavigate } from 'react-router';
import { IoArrowBackSharp } from "react-icons/io5";



const clockwise = keyframes`
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;    
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;    
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;    
  }
`;

const counterclockwise = keyframes`
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;    
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;    
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;    
  }
`;

const StyledDiv = styled.div`
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  background: #49dbfdde;
  color: white;
  border-radius: 12px;
  transition: 1s box-shadow;
  padding: 36px 0px;
  cursor: pointer;
  border: 1px solid rgba(120,120,120,.2);
  margin-top: 20px;

  &:hover {
    box-shadow: 0 5px 35px 0px rgba(0, 0, 0, 0.1);
  }

  &:hover::before, &:hover::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FDA8CF;
    border-radius: 12px;
    z-index: -1;
    animation: ${clockwise} 1s infinite;
  }

  &:hover::after {
    background: #F3CE5E;
    animation: ${counterclockwise} 2s infinite;
  }
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  @media (max-width: 750px) {
    display: block;
  }
`;

const HiddenContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const HiddenDiv = styled.div`
  flex: 1 1 100%;
  height: auto;
  border: 1px #cecccc solid;
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  justify-items: center;
  text-align: center;
  padding: 20px 0;
  cursor: pointer;

  @media (min-width: 600px) {
    flex: 1 1 calc(50% - 10px);
  }
`;

const Dtc = () => {

  const navigate = useNavigate();

  return (
    <div style={{margin:'20px'}}>
      <StyledDiv>
        <Header><div onClick={()=> navigate('/')} style={{display:'flex', alignItems:'center', gap:'5px'}}><IoArrowBackSharp /> Go Back</div><div>DTC/UGC/Ecommerce Brands</div></Header>
      </StyledDiv>
      <HiddenContainer>
        <HiddenDiv onClick={() => navigate('/simply-big')}>
          <div style={{padding:'20px'}}>
            <h3 style={{margin:'0px'}}>Simply Big</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>is an Ecommerce that specialise in sale of Clocks and Mobile phones for the elderly</i>
          </div>
            <ImageComponent src={SimplyBig} alt="Simply Big" />
        </HiddenDiv>
        <HiddenDiv onClick={() => navigate('/bounce-nutrition')}>
          <div style={{padding:'20px'}}>
            <h3 style={{margin:'0px'}}>Bounce Nutrition</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>is an ecommerce brand that produce and sell supplements, gummies, etc</i>
          </div>
          <ImageComponent src={BounceNutrition} alt="Bounce Nutrition" />
        </HiddenDiv>
        <HiddenDiv onClick={() => navigate('/sleep-by-santi')}>
          <div style={{padding:'20px'}}>
            <h3 style={{margin:'0px'}}>Sleep by Santi</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>is an ecommerce brand that sell Beddings</i>
          </div>  
          <ImageComponent src={SleepBySanti} alt="Sleep by Santi" />
        </HiddenDiv>
        <HiddenDiv onClick={() => navigate('/raycon')}>
          <div style={{padding:'20px'}}>
            <h3 style={{margin:'0px'}}>Raycon</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>Raycon is an ecommerce brand that sell Earbuds and headphones</i>
          </div>  
          <ImageComponent src={Raycon} alt="Raycon" />
        </HiddenDiv>
        <HiddenDiv onClick={() => navigate('/clearly-filtered')}>
          <div style={{padding:'20px'}}>
            <h3 style={{margin:'0px'}}>Clearly filtered</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>is a water filter and water bottle brand</i>
          </div>
          <ImageComponent src={ClearlyFiltered} alt="Clearly filtered" />
        </HiddenDiv>
        <HiddenDiv onClick={() => navigate('/car-mats-culture')}>
        <div style={{padding:'20px'}}>
            <h3 style={{margin:'0px'}}>Car Mats Culture</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>is an Australian ecommerce brand that sell Car mats and car accessories</i>
          </div>  
          <ImageComponent src={Carmats} alt="Car Mats Culture" />
        </HiddenDiv>
        <HiddenDiv onClick={() => navigate('/tiny-bio')}>
          <div style={{padding:'20px'}}>
            <h3 style={{margin:'0px'}}>Tiny Bio</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>is a Chatbot for research students and scientists</i>
          </div>
          <ImageComponent src={TinyBio} alt="Tiny Bio" />
        </HiddenDiv>
        {/* <HiddenDiv onClick={() => navigate('/other-ecom-brands')}>
          <div style={{padding:'20px'}}>
            <h3 style={{margin:'0px'}}>Other Ecom Brands</h3>
          </div>  
          <ImageComponent src={OtherEcom} alt="Other Ecom Brands" />
        </HiddenDiv> */}
      </HiddenContainer>
    </div>
  );
};

export default Dtc;
