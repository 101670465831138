import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Thumbnail from '../assets/maxresdefault.jpg';
import PlaylistEmbed from './playlistEmbed';
import { useNavigate } from 'react-router';

const clockwise = keyframes`
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;    
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;    
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;    
  }
`;

const counterclockwise = keyframes`
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;    
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;    
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;    
  }
`;

const StyledDiv = styled.div`
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  color: #fff;
  background: #6849fd;
  border-radius: 12px;
  transition: 1s box-shadow;
  padding: 36px 0px;
  cursor: pointer;
  border: 1px solid rgba(120,120,120,.2);
  margin-top: 20px;

  &:hover {
    box-shadow: 0 5px 35px 0px rgba(0, 0, 0, 0.1);
  }

  &:hover::before, &:hover::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FDA8CF;
    border-radius: 12px;
    z-index: -1;
    animation: ${clockwise} 1s infinite;
  }

  &:hover::after {
    background: #F3CE5E;
    animation: ${counterclockwise} 2s infinite;
  }
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  @media (max-width: 750px) {
    display: block;
  }
  div{
    font-size: 12px; 
    font-weight:200; 
    @media (max-width: 750px) {
    display: block;
  }
  }
`;

const ExtraContent = styled.div`
  display: ${props => (props.expanded ? 'block' : 'none')};
  padding: 0px 10px;
`;

const HiddenContainer = styled.div`
  display: ${props => (props.expanded ? 'flex' : 'none')};
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const HiddenDiv = styled.div`
  flex: 1 1 100%;
  height: auto;
  border: 1px #4991fd solid;
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  justify-items: center;
  text-align: center;
  padding: 20px 0;
  @media (min-width: 600px) {
    flex: 1 1 calc(50% - 10px);
  }
  h3{
    cursor: pointer;
    &:hover {
    color: blue;
    }
  }
  img{
    cursor: pointer;
  }
`;

const EditingStyle = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <StyledDiv expanded={expanded} onClick={handleToggle}>
        <Header>Editing Style <div>Content types</div></Header>
        <ExtraContent expanded={expanded}>
          <p>Find your style</p>
        </ExtraContent>
      </StyledDiv>
      <HiddenContainer expanded={expanded}>
        <HiddenDiv>
            <h3 style={{margin:'0px'}} onClick={() => navigate('/documentary')}>DOCUMENTARY/FACELESS YOUTUBE VIDEO</h3>
            {/*<i style={{fontSize:'12px', textDecoration:''}}>This Videos were edited for Instagram</i>*/}
            {/* <p>I specialised in Video editing of both short form and long form True Crime content</p> */}
            <PlaylistEmbed playlistId="PLdklcLR-RKh-A6Ykke82En7l5T9DVF-Tw" thumbNail={Thumbnail} />
        </HiddenDiv>
        <HiddenDiv>
            <h3 style={{margin:'0px'}} onClick={() => navigate('/ugc')}>DTC/UGC/Ecom ads</h3>
            {/*<i style={{fontSize:'12px', textDecoration:''}}>This Videos were edited for Instagram</i>*/}
            {/* <p>I specialised in Video editing of both short form and long form True Crime content</p> */}
            <PlaylistEmbed playlistId="PLdklcLR-RKh8EjUkMXVWXH6y3y3NwwHvu" thumbNail={Thumbnail} />
        </HiddenDiv>
        <HiddenDiv>
            <h3 style={{margin:'0px'}} onClick={() => navigate('/shorts')}>Shorts</h3>
            {/*<i style={{fontSize:'12px', textDecoration:''}}><b>(60k Subs)</b>Youtube channel on strategic communication breakdown and analysis</i>*/}
            {/* <p>I specialised in editing reaction and analytical videos</p> */}
            <PlaylistEmbed playlistId="PLdklcLR-RKh-3JW8BkKr6jbE2dItsXqPD" thumbNail={Thumbnail} />
        </HiddenDiv>
        <HiddenDiv>
            <h3 style={{margin:'0px'}} onClick={() => navigate('/talking-head')}>Talking Head Videos</h3>
            {/* <p>I specialised in editing reaction and analytical videos</p> */}
            <PlaylistEmbed playlistId="PLdklcLR-RKh_tin-lLGs9uCLBUt2ncEEB" thumbNail={Thumbnail} />
        </HiddenDiv>
        {/* <HiddenDiv>
            <img src={Ewu} alt="Channel" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>Instagram Videos</h3>
            <i style={{fontSize:'12px', textDecoration:''}}>This Videos were edited for Instagram</i>
            <PlaylistEmbed playlistId="PLdklcLR-RKh9LfAYQ0h4dhcj-eMm0BzOs" thumbNail={Thumbnail} />
        </HiddenDiv>
        <HiddenDiv>
            <img src={Ronan} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>Personal Videos</h3>
            <i style={{fontSize:'12px', textDecoration:''}}><b>(60k Subs)</b>Youtube channel on strategic communication breakdown and analysis</i>
            <PlaylistEmbed playlistId="PLdklcLR-RKh_i07rHOIcW1c0cykAjfyF8" thumbNail={Thumbnail} />
        </HiddenDiv>
        <HiddenDiv>
            <img src={Ronan} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>Youtube Videos</h3>
            <i style={{fontSize:'12px', textDecoration:''}}><b>(60k Subs)</b>Youtube channel on strategic communication breakdown and analysis</i>
            <PlaylistEmbed playlistId="PLdklcLR-RKh--0ptWuDSVaub4FxRcIxE2" thumbNail={Thumbnail} />
        </HiddenDiv>
        <HiddenDiv>
            <img src={Ronan} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>Podcast</h3>
            <i style={{fontSize:'12px', textDecoration:''}}><b>(60k Subs)</b>Youtube channel on strategic communication breakdown and analysis</i>
            <PlaylistEmbed playlistId="PLdklcLR-RKh8FoGVSk4WB8UWikzvY02P7" thumbNail={Thumbnail} />
        </HiddenDiv>
        <HiddenDiv>
            <img src={Ronan} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>Video Sales Letter</h3>
            <i style={{fontSize:'12px', textDecoration:''}}><b>(60k Subs)</b>Youtube channel on strategic communication breakdown and analysis</i>
        </HiddenDiv>
        <HiddenDiv>
            <img src={Ronan} alt="Avatar" style={{width:'100px', height:'100px', borderRadius:'50%'}}/>
            <h3 style={{margin:'0px'}}>UGC AD</h3>
            <i style={{fontSize:'12px', textDecoration:''}}><b>(60k Subs)</b>Youtube channel on strategic communication breakdown and analysis</i>
            <PlaylistEmbed playlistId="PLdklcLR-RKh8q_M0T6EtxjvBjfrBOp6Mg" thumbNail={Thumbnail} />
        </HiddenDiv> */}
      </HiddenContainer>
    </>
  );
};

export default EditingStyle;
