import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const clientId = '578770104710-re9eo21rnejh70farvvc32kmmgn7geo3.apps.googleusercontent.com';
const SCOPES = 'https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/analytics.readonly';
const SEARCH_CONSOLE_API_URL = 'https://www.googleapis.com/webmasters/v3/sites/edmondedit.com/searchAnalytics/query'; // Replace YOUR_SITE_URL with your actual site URL
const ANALYTICS_API_URL = 'https://analyticsdata.googleapis.com/v1beta/properties/450541032:runReport'; // Replace YOUR_GA4_PROPERTY_ID with your GA4 Property ID

const AnalyticsView = () => {
  const [searchConsoleData, setSearchConsoleData] = useState([]);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;

  const login = useGoogleLogin({
    onSuccess: tokenResponse => setAccessToken(tokenResponse.access_token),
    scope: SCOPES
  });

  useEffect(() => {
    if (accessToken) {
      // const fetchEarliestDate = async () => {
      //   const today = new Date().toISOString().split('T')[0];

      //   const response = await axios.post(
      //     SEARCH_CONSOLE_API_URL,
      //     {
      //       startDate: '2024-07-01',
      //       endDate: today,
      //       dimensions: ['date'],
      //       rowLimit: 1
      //     },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${accessToken}`
      //       }
      //     }
      //   );

      //   return response.data.rows[0].keys[0];
      // };

      const fetchSearchConsoleData = async () => {
        setLoading(true);
        try {
          //const earliestDate = await fetchEarliestDate();
          const today = new Date().toISOString().split('T')[0];

          const response = await axios.post(
            SEARCH_CONSOLE_API_URL,
            {
              startDate: '2024-07-01',
              endDate: today,
              dimensions: ['date'],
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            }
          );

          setSearchConsoleData(response.data.rows);
        } catch (error) {
          console.error('Error fetching search console data:', error);
        } finally {
          setLoading(false);
        }
      };

      const fetchAnalyticsData = async () => {
        setLoading(true);
        try {
          const response = await axios.post(
            ANALYTICS_API_URL,
            {
              dimensions: [{ name: 'date' }],
              metrics: [{ name: 'activeUsers' }],
              dateRanges: [{ startDate: '30daysAgo', endDate: 'today' }]
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            }
          );

          setAnalyticsData(response.data);
        } catch (error) {
          console.error('Error fetching analytics data:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchSearchConsoleData();
      fetchAnalyticsData();
    }
  }, [accessToken]);

  const searchConsoleChartData = {
    labels: searchConsoleData?.map(row => row.keys[0]),
    datasets: [
      {
        label: 'Clicks',
        data: searchConsoleData?.map(row => row.clicks),
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
      },
    ],
  };

  const analyticsChartData = analyticsData ? {
    labels: analyticsData.rows.map(row => row.dimensionValues[0].value),
    datasets: [
      {
        label: 'Active Users',
        data: analyticsData.rows.map(row => row.metricValues[0].value),
        borderColor: 'rgba(153,102,255,1)',
        backgroundColor: 'rgba(153,102,255,0.2)',
      },
    ],
  } : {};

  const currentPageData = searchConsoleData?.slice(page * itemsPerPage, (page + 1) * itemsPerPage);

  const handleNextPage = () => {
    if ((page + 1) * itemsPerPage < searchConsoleData?.length) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  return (
    <Container>
      {!accessToken ? (
        <Button onClick={() => login()}>Login with Google to View Data</Button>
      ) : loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {searchConsoleData?.length > 0 && (
            <>
              <h2>Google Search Console Data</h2>
              <Line data={searchConsoleChartData} />
              <Table>
                <thead>
                  <tr>
                    <th>Query</th>
                    <th>Clicks</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((row, index) => (
                    <tr key={index}>
                      <td>{row.keys[0]}</td>
                      <td>{row.clicks}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination>
                <Button onClick={handlePreviousPage} disabled={page === 0}>Previous</Button>
                <Button onClick={handleNextPage} disabled={(page + 1) * itemsPerPage >= searchConsoleData.length}>Next</Button>
              </Pagination>
            </>
          )}
          {analyticsData && (
            <>
              <h2>Google Analytics Data</h2>
              <Line data={analyticsChartData} />
              <p>Total Unique Visits: {analyticsData.rows.reduce((sum, row) => sum + parseInt(row.metricValues[0].value), 0)}</p>
            </>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 20px;
  font-family: Arial, sans-serif;
`;

const Button = styled.button`
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
    text-align: left;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export default AnalyticsView;
